<template>
  <div>
    <v-card outlined>
      <v-simple-table>
        <template v-slot:default>
          <tbody>

          <tr class="bg-snipper">
            <td class="font-weight-bold">Statut</td>
            <td>
              <SwitchStatus :value="investigation.is_active"/>
            </td>
          </tr>

          <tr>
            <td class="font-weight-bold">Image</td>
            <td>
              <v-img v-if="investigation.image"
                     :src="$fileUrl + investigation.image"
                     width="40"
                     height="40"
                     class="zoom-pointer rounded"
                     @click="[image = [{src: $fileUrl + investigation.image}],visible = true]"/>

              <v-img v-else
                     :src="require('@/assets/no-image.png')"
                     width="40"
                     height="40"/>
            </td>
          </tr>

          <tr class="bg-snipper">
            <td class="font-weight-bold">Description</td>
            <td>{{ investigation.description.fr || '-' }}</td>
          </tr>

          <tr>
            <td class="font-weight-bold">Date de début</td>
            <td>{{ investigation.start_date }}</td>
          </tr>

          <tr class="bg-snipper">
            <td class="font-weight-bold">Date de fin</td>
            <td>{{ investigation.end_date }}</td>
          </tr>

          <tr>
            <td class="font-weight-bold">Montant</td>
            <td>
              <v-chip color="blue" dark small>{{ investigation.amount | toCurrency }}</v-chip>
            </td>
          </tr>

          <tr>
            <td class="font-weight-bold ">Est une modéle</td>
            <td>
              <v-icon v-if="!investigation.is_model" color="error">mdi-close</v-icon>
              <v-icon v-else color="success">mdi-check</v-icon>
            </td>
          </tr>

          <tr class="bg-snipper">
            <td class="font-weight-bold">Progression</td>
            <td>
              <div class="d-flex align-center">
                <v-progress-linear :value="20" color="blue" rounded height="5"/>
                <strong class="mx-3">30%</strong>
              </div>
            </td>
          </tr>

          <tr>
            <td class="font-weight-bold">Nombre de tâche</td>
            <td>
              <v-chip color="primary" small>
                <v-icon small left>mdi-file-document-multiple-outline</v-icon>
                {{ investigation.tasks.length }}
              </v-chip>
            </td>
          </tr>

          </tbody>
        </template>
      </v-simple-table>

    </v-card>

    <h3 class="py-5 blue--text">
      <v-avatar color="blue" size="40">
        <v-icon dense dark>mdi-file-document-multiple-outline</v-icon>
      </v-avatar>

      Tâches :
    </h3>

    <v-card flat class="bg-snipper mb-8" outlined v-for="(task,i) in investigation.tasks" :key="i">

      <v-btn color="primary"
             class="transform-none"
             fab dark
             depressed
             small absolute
             top right>
        {{ i + 1 }}
      </v-btn>

      <v-card-title class="fs-16">

        Type de question :
        <v-card-subtitle>
          {{ task.question_type.name[$lang] }}
          <v-icon right color="blue">{{ task.question_type.icon }}</v-icon>
        </v-card-subtitle>
        <v-spacer/>

        Type de réponse :
        <v-card-subtitle>
          {{ task.answer_type.name[$lang] }}
          <v-icon right color="blue">{{ task.answer_type.icon }}</v-icon>
        </v-card-subtitle>

      </v-card-title>

      <v-divider/>

      <v-card-text>

        <div class="d-block mb-4" v-if="lang.includes('fr')">
          <span class="text--primary font-weight-bold">Question (FR) :</span>
          {{ task.question.question['fr'] }}
        </div>

        <div class="d-block mb-4" v-if="lang.includes('en')">
          <span class="text--primary font-weight-bold">Question (EN) :</span>
          {{ task.question.question['en'] }}
        </div>

        <div class="d-block mb-4" v-if="lang.includes('ar')">
          <span class="text--primary font-weight-bold">Question (AR) :</span>
          {{ task.question.question['ar'] }}
        </div>

        <div class="d-block mb-4">
          <span class="text--primary font-weight-bold">Fichier :</span>
          <v-chip color="success" small link class="mx-2" v-if="task.file">
            <v-icon small left>mdi-link</v-icon>
            {{ task.file }}
          </v-chip>
          <span class="mx-2" v-else>-</span>
        </div>


        <div v-if="task.question_type.key === 'condition'">
          <div class="d-block mb-4">
            <span class="text--primary font-weight-bold">Conditions :</span>
          </div>

          <v-simple-table class="bg-snipper table-border">
            <template v-slot:default>
              <thead>
              <tr>
                <th>Image</th>
                <th>Code</th>
                <th>Nom</th>
                <th>Box</th>
                <th>Qauntité</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(condition,i) in task.question.conditions"
                  :key="i"
              >
                <td>
                  <v-img v-if="condition.product.image"
                         :src="$fileUrl + condition.product.image"
                         width="40"
                         height="40"
                         class="zoom-pointer rounded"
                         @click="[image = [{src: $fileUrl + condition.product.image}],visible = true]"/>

                  <v-img v-else
                         :src="require('@/assets/no-image.png')"
                         width="40"
                         height="40"/>

                </td>
                <td>{{ condition.product.code }}</td>
                <td>{{ condition.product.name }}</td>
                <td>
                  <v-icon color="success" dense v-if="condition.is_box">mdi-check</v-icon>
                  <v-icon color="red" dense v-else>mdi-close</v-icon>
                </td>
                <td>{{ condition.quantity }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>

        <div v-if="task.answer_type.key === 'radio' || task.answer_type.key === 'checkbox'">

          <div class="d-block mb-4">
            <span class="text--primary font-weight-bold">Options :</span>
          </div>


          <v-row v-for="(option,i) in task.question.options" :key="i" class="table-border my-2">

            <v-col v-if="lang.includes('fr')">
              <v-text-field :prepend-inner-icon="task.answer_type.icon"
                            readonly
                            dense
                            disabled
                            hint="FR"
                            persistent-hint
                            :label="'Option ' +(i+1)"
                            v-model="option.option['fr'] "/>
            </v-col>

            <v-col v-if="lang.includes('en')">
              <v-text-field :prepend-inner-icon="task.answer_type.icon"
                            readonly
                            dense
                            disabled
                            hint="EN"
                            persistent-hint
                            :label="'Option ' +(i+1)"
                            v-model="option.option['en'] "/>
            </v-col>

            <v-col v-if="lang.includes('ar')">
              <v-text-field :prepend-inner-icon="task.answer_type.icon"
                            readonly
                            dense
                            disabled
                            hint="AR"
                            persistent-hint
                            :label="'Option ' +(i+1)"
                            v-model="option.option['ar'] "/>
            </v-col>

          </v-row>

        </div>

      </v-card-text>

    </v-card>

    <vue-easy-lightbox escDisabled
                       moveDisabled
                       :visible="visible"
                       :imgs="image"
                       :index="index"
                       @hide="visible = false"></vue-easy-lightbox>

  </div>
</template>

<script>

export default {
  props: ['investigation', 'lang'],
  data() {
    return {


      /**Lightbox**/
      image: '',
      visible: false,
      index: 0,
    }
  }
}
</script>

<style scoped>

</style>