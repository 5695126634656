<template>
  <div>
    <LangDialog ref="langDialog"/>
    <v-row>
      <v-col cols="4">
        <v-text-field rounded filled dense
                      v-model="keyword"
                      append-icon="mdi-magnify"
                      single-line hide-details clearable
                      :placeholder="$t('field.search')"/>
      </v-col>
      <v-col cols="8" :class="'text-'+$float">

        <FilterDialog @filter="$emit('filter',$event)"/>

        <Export link="campaigns/export"/>

        <Refresh @refresh="$emit('refresh')"/>

        <v-btn color="primary"
               rounded
               depressed
               @click="newCampaign">
          <v-icon left>mdi-plus</v-icon>
          {{ $t('btn.add') }}
        </v-btn>

      </v-col>
    </v-row>
  </div>
</template>

<script>
import FilterDialog from "@/views/campaigns/list/components/FilterDialog";

export default {
  components: {FilterDialog},
  data() {
    return {
      keyword: null
    }
  },
  methods: {
    newCampaign() {
      this.$refs.langDialog.open()
    },
  },
  watch: {
    keyword() {
      this.$emit('search', this.keyword)
    }
  }
}
</script>

<style scoped>

</style>