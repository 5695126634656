<template>
  <v-dialog v-if="investigations"
            max-width="1000"
            scrollable
            v-model="dialog">

    <v-card :loading="isLoading" :disabled="isLoading">
      <v-card-title>
        Enquêtes
        <v-spacer/>
        <v-icon @click="close">mdi-close</v-icon>
      </v-card-title>
      <v-divider/>
      <v-card-text class="pa-4">

        <v-row>
          <v-col cols="3">

            <v-list nav shaped class="fixed">
              <v-list-item-group v-model="model" mandatory>
                <v-list-item
                    class="translateY"
                    active-class="blue white--text"
                    v-for="(item, i) in investigations"
                    :key="i"
                    :value="item.id">
                  <v-list-item-icon>
                    <v-icon dense> mdi-order-bool-descending-variant</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title> {{ $func.setName(item.name, lang) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>

          </v-col>

          <v-col cols="9">
            <InvestigationDetail :lang="lang" :investigation="investigations.find(el => el.id === model)"/>
          </v-col>

        </v-row>

      </v-card-text>

    </v-card>

  </v-dialog>

</template>

<script>

import InvestigationDetail from "@/views/campaigns/list/components/InvestigationDetail";

export default {
  components: {InvestigationDetail},
  data() {
    return {
      dialog: false,
      isLoading: false,
      investigations: null,
      lang: null,
      model: null,
    }
  },
  methods: {
    open(investigations, lang) {
      this.model = investigations[0].id
      this.investigations = investigations
      this.lang = lang
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
  },
}
</script>

<style scoped>

</style>