<template>
  <v-dialog v-if="form.id"
            max-width="500"
            scrollable
            v-model="dialog">
    <form @submit.prevent="save">
      <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title>
          Modifier la planification
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-4">


          <v-dialog
              v-model="startDateDialog"
              persistent
              width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field :value="form.start_date"
                            :label="$t('field.startDate')"
                            class="required"
                            readonly
                            v-bind="attrs" v-on="on">
                <v-icon slot="prepend-inner"
                        color="blue">
                  mdi-calendar
                </v-icon>
              </v-text-field>
            </template>
            <v-date-picker :min="dateNow"
                           @change="startDateDialog=false"
                           v-model="form.start_date"
                           scrollable>
            </v-date-picker>
          </v-dialog>


          <v-dialog
              v-model="endDateDialog"
              width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field :value="form.end_date"
                            :label="$t('field.endDate')"
                            class="required"
                            readonly
                            v-bind="attrs" v-on="on">
                <v-icon slot="prepend-inner"
                        color="blue">
                  mdi-calendar
                </v-icon>
              </v-text-field>
            </template>
            <v-date-picker v-model="form.end_date"
                           @change="endDateDialog=false"
                           scrollable>
            </v-date-picker>
          </v-dialog>


        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary"
                 :disabled="isLoading"
                 type="submit"
                 depressed>
            <v-icon left>mdi-content-save</v-icon>
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </v-dialog>

</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      dateNow: this.$moment().format('YYYY-MM-DD'),
      startDateDialog: false,
      endDateDialog: false,
      dialog: false,
      isLoading: false,
      form: {
        id: null,
        start_date: null,
        end_date: null,
      },
      errors: {},
    }
  },
  methods: {
    open(item) {
      this.form.id = item.id
      this.form.start_date = item.start_date
      this.form.end_date = item.end_date
      this.dialog = true
      this.errors = {}
    },
    close() {
      this.dialog = false
    },
    save() {
      this.errors = {}
      this.$Progress.start()
      this.isLoading = true

      let url = "campaigns/update-planning"

      HTTP.post(url, this.form).then(() => {
        this.$emit('refresh')
        this.close()
        this.isLoading = false
        this.$successMessage = this.$i18n.t('message.succssStore')
        this.$Progress.finish()
      }).catch((err) => {
        this.isLoading = false
        this.$Progress.fail()
        this.errors = err.response.data.errors
        this.$errorMessage = this.$i18n.t('message.catchError')
        console.log(err)
      })
    }
  },
}
</script>

<style scoped>

</style>