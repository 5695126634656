<template>
  <div>
    <v-container fluid>
      <breadcrumbs :breadcrumbs="breadcrumbs"/>
      <v-row justify="center">
        <v-col cols="12">
          <v-card class="rounded-lg shadow" flat :loading="isLoading">

            <v-overlay :value="overlay"
                       absolute
                       :color="$vuetify.theme.dark ?'secondary':'white'">
              <v-progress-circular
                  indeterminate
                  size="60"
                  color="primary"
              ></v-progress-circular>
            </v-overlay>

            <v-card-title class="d-block">
              <TableHeader @refresh="[filter = {} ,getAllCampaigns()]"
                           @filter="[filter = $event,getAllCampaigns()]"
                           @search="[keyword = $event,getAllCampaigns()]"/>
            </v-card-title>

            <v-divider/>

            <v-card-text class="pa-0">

              <v-skeleton-loader v-if="isLoading" type="table"/>

              <div v-else>
                <v-simple-table v-if="campaigns.length">
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th width="20">Statut</th>
                      <th>Image</th>
                      <th>Nom de campagne</th>
                      <th>Lang</th>
                      <th>Planification</th>
                      <th class="text-center">Enquêtes</th>
                      <th>Date de création</th>
                      <th>Analyses</th>
                      <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item,i) in campaigns" :key="i">
                      <td>
                        <SwitchStatus url="campaigns/change-status"
                                      @overlay="overlay = $event"
                                      :id="item.id" :value="item.is_active"
                                      @refresh="getAllCampaigns"/>
                      </td>

                      <td>
                        <v-img v-if="item.image"
                               :src="$fileUrl + item.image"
                               width="40"
                               height="40"
                               class="zoom-pointer rounded"
                               @click="[image = [{src: $fileUrl + item.image}],visible = true]"/>

                        <v-img v-else
                               :src="require('@/assets/no-image.png')"
                               width="40"
                               height="40"/>
                      </td>

                      <td>
                        {{ $func.setName(item.name, item.lang) }}
                      </td>

                      <td>
                        {{ item.lang.join(',').toUpperCase() }}
                      </td>

                      <td>
                        <span class="d-block">
                               <v-icon left small color="blue"
                                       style="margin-top: -4px">mdi-sort-clock-ascending-outline</v-icon>
                          {{ item.start_date }}
                        </span>
                        <span class="d-block">
                              <v-icon left small color="blue"
                                      style="margin-top: -4px">mdi-sort-clock-descending-outline</v-icon>
                          {{ item.end_date }}
                        </span>
                      </td>

                      <td class="text-center">
                        <div class="translateY py-4">
                          <v-chip color="success" small
                                  @click="$refs.investigationsDialog.open(item.investigations,item.lang)">
                            <v-icon left small>mdi-text-box-check-outline</v-icon>
                            {{ item.investigations.length }}
                          </v-chip>
                        </div>
                      </td>

                      <td>
                        {{ item.created_at }}
                      </td>

                      <td>
                        <TextLink title="Analyses" :url="item.name" icon="mdi-chart-pie"/>
                      </td>

                      <td>
                        <div class="d-flex">
                          <BtnLink color="blue" :url="item.name" icon="mdi-plus-circle"/>
                          <ActionsMenu @overlay="overlay = $event" :campaign="item" @refresh="getAllCampaigns"/>
                        </div>
                      </td>

                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <NoResult v-else/>
              </div>


            </v-card-text>

            <v-divider v-if="total > $totalPerPage"/>
            <v-card-actions v-if="total > $totalPerPage">
              <div class="w-100">
                <v-select dense filled
                          hide-details
                          :items="$perPage"
                          v-model="perPage"></v-select>
              </div>
              <v-spacer/>
              <v-pagination total-visible="10"
                            circle
                            v-model="pagination.current"
                            :length="pagination.total"
                            @input="getAllCampaigns"></v-pagination>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <vue-easy-lightbox escDisabled
                         moveDisabled
                         :visible="visible"
                         :imgs="image"
                         :index="index"
                         @hide="visible = false"></vue-easy-lightbox>

      <InvestigationsDialog ref="investigationsDialog"/>

    </v-container>
  </div>
</template>

<script>

import {HTTP} from "@/http-common";
import TableHeader from "@/views/campaigns/list/components/TableHeader";
import ActionsMenu from "@/views/campaigns/list/components/ActionsMenu";
import InvestigationsDialog from "@/views/campaigns/list/components/InvestigationsDialog";

export default {
  components: {ActionsMenu, TableHeader, InvestigationsDialog},
  data() {
    return {
      overlay: false,
      isLoading: false,
      campaigns: [],
      total: 0,
      perPage: 10,
      pagination: {
        current: 1,
        total: 0
      },

      filter: null,
      keyword: null,

      /**Lightbox**/
      image: '',
      visible: false,
      index: 0,
    }
  },
  methods: {
    getAllCampaigns() {
      this.$Progress.start()
      this.isLoading = true
      HTTP.get('/campaigns?page=' + this.pagination.current, {
        params: {
          keyword: this.keyword,
          filter: this.filter,
          perPage: this.perPage,
        }
      }).then(res => {
        this.pagination.current = res.data.data.current_page;
        this.pagination.total = res.data.data.last_page;
        this.total = res.data.data.total;
        this.campaigns = res.data.data.data
        this.$Progress.finish()
        this.isLoading = false
      }).catch(err => {
        this.$Progress.fail()
        this.$errorMessage = this.$i18n.t('message.catchError')
        this.isLoading = false
        console.log(err)
      })
    }
  },
  created() {
    this.getAllCampaigns()
  },
  watch: {
    perPage() {
      this.getAllCampaigns()
    }
  },
  computed: {
    breadcrumbs() {
      return [
        {to: '/campaigns', text: 'breadcrumb.campaigns'},
      ]
    },
  }
};
</script>

